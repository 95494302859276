export const CREATE_REPORT_REQUEST = "CREATE_REPORT_REQUEST";
export const CREATE_REPORT_SUCCESS = "CREATE_REPORT_SUCCESS";
export const CREATE_REPORT_FAILURE = "CREATE_REPORT_FAILURE";

export const FIND_REPORT_BY_ID_REQUEST = "FIND_REPORT_BY_ID_REQUEST";
export const FIND_REPORT_BY_ID_SUCCESS = "FIND_REPORT_BY_ID_SUCCESS";
export const FIND_REPORT_BY_ID_FAILURE = "FIND_REPORT_BY_ID_FAILURE";

export const FIND_ALL_REPORT_REQUEST = "FIND_ALL_REPORT_REQUEST";
export const FIND_ALL_REPORT_SUCCESS = "FIND_ALL_REPORT_SUCCESS";
export const FIND_ALL_REPORT_FAILURE = "FIND_ALL_REPORT_FAILURE";










